<script setup>
import { ref, provide } from 'vue'

import WhatAreWe from '../components/WhatAreWe.vue'
import AboutUs from '../components/AboutUs.vue'
import Cases from '../components/Cases.vue'
import Contact from '../components/Contact.vue'

const items = ref([{
    text: "navBigPear.about",
    href: "#about-us"
},
{
    text: "navBigPear.cases",
    href: "#cases"
},
{
    text: "navBigPear.contact",
    href: "#contact"
}])
provide('navbar.items', items)

</script>

<template>
    <div class="bigpear-landing">
        <what-are-we />
        <about-us />
        <cases />
        <contact />
    </div>
</template>

<style lang="scss">
.bigpear-landing .go-to-bigpear {
    display: none;
}
</style>
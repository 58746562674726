<script setup>
import Hero from '../layouts/Hero.vue';
import data from "../store/projects.json";
const projects = data.projects.filter(x => x['front-page']);
</script>

<template>
    <Hero v-for="project in projects" :class="`is-${project.slug}`" :key="project.title" id="cases">
        <template #hero-body>
            <div class="columns is-multiline is-centered">
                <div class="column">
                    <picture class="image case">
                        <img :src="project.preview" :alt="$t(project.title)" />
                    </picture>
                </div>
                <div class="column">
                    <div class="case-content content">
                        <img
                        class="case-logo"
                        :src="project.logo"
                        :alt="$t(project.title)"
                        />
                        <p>{{ $t(project.description) }}</p>
                    </div>
                </div>
            </div>
        </template>
    </Hero>
</template> 

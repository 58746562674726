<script setup>
import Hero from '../layouts/Hero.vue'

const cards = [
    { title: "highlights.cards.solution.title", content: "highlights.cards.solution.content" },
    { title: "highlights.cards.efficiency.title", content: "highlights.cards.efficiency.content" },
    { title: "highlights.cards.system.title", content: "highlights.cards.system.content" },
    { title: "highlights.cards.economic.title", content: "highlights.cards.economic.content" },
    { title: "highlights.cards.robust.title", content: "highlights.cards.robust.content" },
    { title: "highlights.cards.comunication.title", content: "highlights.cards.comunication.content" },
    { title: "highlights.cards.centralized.title", content: "highlights.cards.centralized.content" },
    { title: "highlights.cards.quality.title", content: "highlights.cards.quality.content" },
    { title: "highlights.cards.supervision.title", content: "highlights.cards.supervision.content" },
    { title: "highlights.cards.guaranty.title", content: "highlights.cards.guaranty.content" },
    { title: "highlights.cards.sovereignty.title", content: "highlights.cards.sovereignty.content" },
    { title: "highlights.cards.security.title", content: "highlights.cards.security.content" }
];
</script>

<template>
    <Hero id="highlights" class="highlights">
        <template #hero-body>
            <h3 class="title"> {{ $t('highlights.title') }} </h3>
            <div class="columns is-multiline">
                <div class="column is-half-tablet" v-for="card in cards">
                    <div class="box">
                        <p>
                            <strong>
                                {{ $t(card.title) }}
                            </strong>
                            {{ $t(card.content) }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </Hero>
</template>

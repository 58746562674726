<script setup>
import Lang from './Lang.vue'
import { inject } from 'vue'

const items = inject('navbar.items', [])
</script>

<template>
    <a v-for="item in items" :href="item.href" :title="$t(item.text)" :key="$t(item.text)" class="navbar-item">
        {{ $t(item.text) }}
    </a>
    <router-link class="navbar-item go-to-bigpear" to="/">BigPear</router-link>
    <router-link class="navbar-item go-to-olmo" to="/olmo">Olmo</router-link>
    <div class="navbar-item is-hidden-mobile">
        <lang />
    </div>
</template>
<script setup>
import Logo from './Logo.vue';
import LogoOlmo from './LogoOlmo.vue';

const name = 'bigpear';


</script>
<template>
    <a class="navbar-item brand brand-bigpear" :alt="name" href="/" v-if="$route.name=='home'">
        <Logo />
    </a>
    <a class="navbar-item brand brand-olmo" :alt="name" href="/olmo" v-if="$route.name=='olmo'">
        <LogoOlmo />
    </a>
</template>
<template>
<svg id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 716.8 136" style="enable-background:new 0 0 716.8 136;" xml:space="preserve">
    <path d="M49.9,91.6c-0.9-0.2-1-1.4-0.2-1.8c5-2.9,8-8.5,8-15.7c0-12-8.8-19.4-23-19.4H0v80h38.2c16.4,0,26.3-8.9,26.3-22.3
        C64.5,101.7,58.8,94.2,49.9,91.6L49.9,91.6z M36.7,127H8.5V62.3h24.8c10.2,0,16,4.7,16,13.3c0,5.7-2.8,9.7-7.8,11.6
        c-1.9,0.7-3.3,2.5-3.3,4.5v0.1c0,2.3,1.7,4.3,4,4.7c8.5,1.5,13.3,6.8,13.3,14.9C55.4,121.3,48.6,127,36.7,127L36.7,127z"/>
    <path d="M48.7,9.2c0-5.1-4.1-9.2-9.2-9.2c-1.1,0-2.2,0.2-3.2,0.6c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.1,0.1
        c-3,1.3-10.1,4.9-13.8,12.8c-4.8,10-0.3,20.9,2.9,26.6c0.6,1,2.1,0.5,2-0.6c-0.3-4.6,0.2-11.7,4.6-16.5c2.9-3.2,6.9-4.3,9.5-4.8l0,0
        C45.4,17.6,48.7,13.8,48.7,9.2L48.7,9.2z"/>
    <path d="M117.9,134.7v-80h8.5v80H117.9z"/>
    <path d="M192,124.4c-7.8-7.8-11.7-17.7-11.7-29.7s3.9-21.8,11.7-29.6c7.9-7.9,17.6-11.8,29.4-11.8c10.1,0,18.5,2.5,25.4,7.7v8.6
        c-7.4-5.6-15.7-8.4-24.9-8.4s-17.3,3.2-23.5,9.5c-6.3,6.3-9.4,14.4-9.4,24.1s3.1,17.7,9.3,24c6.3,6.3,14,9.4,23,9.4
        c9.6,0,17.1-2.7,22.8-8.4V88.7h8.4v34.4c-6.9,7.7-18.2,12.9-31.1,12.9C209.7,136,200,132.2,192,124.4L192,124.4z"/>
    <path d="M304.6,134.7v-80h35c8.6,0,15.4,2.3,20.3,6.8c5,4.5,7.5,10.2,7.5,17.4s-2.5,13-7.5,17.5c-4.9,4.5-11.8,6.8-20.3,6.8h-18.5
        v31.7L304.6,134.7L304.6,134.7z M321.1,89h17.4c7.9,0,12.6-3.5,12.6-10s-4.7-10-12.6-10h-17.4V89L321.1,89z"/>
    <path d="M414.3,134.7v-80H473v14.8h-42.3v15.4h34.1v14.5h-34.1v20.6h43.6v14.8L414.3,134.7L414.3,134.7z"/>
    <polygon points="564.8,54.2 549.1,54.2 514.8,134.7 532,134.7 556.8,74.8 581.6,134.7 599.1,134.7 "/>
    <path d="M644.1,134.7v-80h37c8.5,0,15.2,2.2,20.1,6.6c4.9,4.3,7.4,10.1,7.4,17c0,10.6-6.2,19-16.6,22.2l24.7,34.2h-19.1l-23.3-32.4
        h-13.8v32.4L644.1,134.7L644.1,134.7z M660.5,88.5h19.7c7.4,0,11.9-3.4,11.9-9.7s-4.5-9.7-11.9-9.7h-19.7V88.5z"/>
</svg>
</template>
<script setup>
import { ref, provide } from 'vue'

import IsOlmo from '../components/IsOlmo.vue'
import Videos from '../components/Videos.vue'
import WhatIsOlmo from '../components/WhatIsOlmo.vue'
import Highlights from '../components/Highlights.vue'
import Contact from '../components/Contact.vue'

const items = ref([{
    text: "navOlmo.whatis",
    href: "#what-is-olmo"
},
{
    text: "navOlmo.videos",
    href: "#videos"
},
{
    text: "navOlmo.highlights",
    href: "#highlights"
},
{
    text: "navOlmo.contact",
    href: "#contact"
}])

provide('navbar.items', items)

</script>

<template>
    <div class="olmo-landing">
        <is-olmo />
        <what-is-olmo />
        <videos />
        <highlights />
        <contact />
    </div>
</template>

<style lang="scss">
.olmo-landing .go-to-olmo {
    display: none;
}
</style>
